import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";

export interface IStyle {
  img: any;
}

export const ImgStyle: IStyle = StyleSheet.create({
  img: {
    boxSizing: "border-box",
    padding: space.atom,
  },
});
