import * as React from "react";
import { css } from "aphrodite";
import { ParagraphStyle } from "./ParagraphStyle";

interface IProps extends React.AllHTMLAttributes<HTMLParagraphElement> {
  styles?: any;
  children?: React.ReactNode;
}

export const Paragraph: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { styles, children, ...rest } = props;
  return (
    <p className={css(ParagraphStyle.paragraph, styles)} {...rest}>
      {children}
    </p>
  );
};
