import * as React from "react";
import { css } from "aphrodite";
import { FooterStyle } from "./FooterStyle";
import { Paragraph } from "../../atoms/Paragraph";
import { Img } from "../../atoms/Img";
import { A } from "../../atoms/A";
import { Debug } from "../../molecules/Debug";
import { ICurrentUser } from "main/javascripts/models/CurrentUser";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import logoBlackImg from "main/javascripts/images/logo_black.svg";

export interface IProps {
  currentUser: ICurrentUser | null;
}

export class Footer extends React.Component<IProps> {
  public render(): JSX.Element {
    return (
      <footer className={css(FooterStyle.footer)}>
        <div className={css(FooterStyle.mainBlock)}>
          <div className={css(FooterStyle.block, FooterStyle.col)}>
            <a href="/">
              <Img
                src={logoBlackImg}
                alt="たびチャット - 旅行コンシェルジュサービス"
                styles={FooterStyle.logo}
              />
            </a>
            <hr className={css(FooterStyle.hr)} />
            <Paragraph styles={FooterStyle.p}>
              旅行案内LINEアカウント「たびチャット」は、あなたの希望に合わせてホテル、移動手段、現地ツアーを案内します。
            </Paragraph>
            <div className={css(FooterStyle.tel)}>
              カスタマーサポート: 03-6304-0770
            </div>
          </div>
          <div className={css(FooterStyle.block, FooterStyle.col)}>
            <Paragraph styles={FooterStyle.subtitle}>
              ■ 運営会社について
            </Paragraph>
            <hr className={css(FooterStyle.hr)} />
            <A href="http://nin-nin.net" title="会社概要">
              <Paragraph styles={FooterStyle.p}>{">"} 会社情報</Paragraph>
            </A>
            <A href={`${PATH_SCOPE}/statics/service_policy`} title="利用規約">
              <Paragraph styles={FooterStyle.p}>{">"} 利用規約</Paragraph>
            </A>
            <A
              href={`${PATH_SCOPE}/statics/travel_agency_act`}
              title="旅行業登録票"
            >
              <Paragraph styles={FooterStyle.p}>{">"} 旅行業登録票</Paragraph>
            </A>
            <A href="/reputation" title="たびチャットの評判">
              <Paragraph styles={FooterStyle.p}>
                {">"} たびチャットの評判
              </Paragraph>
            </A>
            <A href="/engine" title="タビチャットエンジン">
              <Paragraph styles={FooterStyle.p}>
                {">"} ホテル予約システム「タビチャットエンジン」
              </Paragraph>
            </A>
          </div>
          <div className={css(FooterStyle.block, FooterStyle.col)}>
            <Paragraph styles={FooterStyle.subtitle}>
              ■ 旅行業登録について
            </Paragraph>
            <hr className={css(FooterStyle.hr)} />
            <Paragraph styles={FooterStyle.p}>
              登録番号：旅行業 第3-7312号 <br />
              所属協会：一般社団法人 全国旅行業協会
              <br />
              運営会社：株式会社NinNin
            </Paragraph>
          </div>
        </div>
        <div className={css(FooterStyle.copyrightBlock)}>
          Copyright ©︎ NinNin, Inc. All rights reserved.
        </div>
        <Debug currentUser={this.props.currentUser} />
      </footer>
    );
  }
}
