import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import {
  accentColor,
  backgroundColor,
} from "main/javascripts/styles/base/colorStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import { equalBreakpoint } from "../../../styles/base/responsiveStyle";

export interface IStyle {
  footer: any;
  block: any;
  mainBlock: any;
  col: any;
  copyrightBlock: any;
  logo: any;
  p: any;
  subtitle: any;
  lineBlock: any;
  line: any;
  tel: any;
  hr: any;
}

export const FooterStyle: IStyle = StyleSheet.create({
  footer: {
    backgroundColor: backgroundColor.secondaryLightColor,
    color: "#000",
    padding: "3rem 0",
  },
  mainBlock: {
    maxWidth: "67.25rem",
    display: "flex",
    flexFlow: "row wrap",
    margin: "0 auto 0 auto",
    alignItems: "stretch",
    justifyContent: "space-between",
  },
  col: {
    width: "100%",
    [equalBreakpoint("tablet")]: {
      width: "50%",
    },
    [equalBreakpoint("desktop")]: {
      width: "32%",
    },
  },
  p: {
    color: "#000",
    fontSize: fontSize.caption,
  },
  subtitle: {
    color: "#000",
    fontWeight: fontWeight.bold,
    fontSize: fontSize.body,
  },
  block: {
    padding: space.block,
    boxSizing: "border-box",
    textAlign: "left",
    position: "relative",
  },
  copyrightBlock: {
    maxWidth: "67.25rem",
    fontSize: fontSize.caption,
    margin: "auto",
    padding: `${space.atom3x} ${space.atom2x} ${space.atom2x}`,
    boxSizing: "border-box",
    position: "relative",
  },
  logo: {
    height: "2.625rem",
    display: "block",
    padding: `${space.atom1_5x} ${space.atom}`,
  },
  lineBlock: {
    boxSizing: "border-box",
    padding: space.atom,
  },
  line: {
    display: "block",
    fontSize: fontSize.caption,
    fontWeight: fontWeight.bold,
    textDecoration: "none",
    color: accentColor.lineColor,
    border: `1px solid ${accentColor.lineColor}`,
    padding: space.atom,
    borderRadius: borderRadius.normal,
    boxSizing: "border-box",
    textAlign: "center",
  },
  tel: {
    fontSize: fontSize.caption,
    padding: space.atom,
    lineHeight: lineHeight.body1,
  },
  hr: {
    padding: `${space.atom} ${space.atom} 0`,
    margin: `${space.atom} ${space.atom} 0`,
    boxSizing: "border-box",
    borderColor: "inherit",
  },
});
