import { StyleSheet } from "aphrodite";
import { accentColor } from "main/javascripts/styles/base/colorStyle";
import { fontWeight } from "main/javascripts/styles/base/typographyStyle";

export interface IStyle {
  a: any;
  inline: any;
}

export const AStyle: IStyle = StyleSheet.create({
  a: {
    textDecoration: "none",
    margin: 0,
    padding: 0,
    color: accentColor.primaryColor,
  },
  inline: {
    textDecoration: "underline",
    margin: 0,
    padding: 0,
    color: accentColor.primaryColor,
    fontWeight: fontWeight.bold,
  },
});
