import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { fontSize } from "main/javascripts/styles/base/typographyStyle";
import { textColor } from "main/javascripts/styles/base/colorStyle";

export interface IStyle {
  block: any;
  p: any;
  pDark: any;
}

export const DebugStyle: IStyle = StyleSheet.create({
  block: {
    margin: "auto",
    padding: space.block,
    boxSizing: "border-box",
    textAlign: "center",
    position: "relative",
  },
  p: {
    color: "#000",
    fontSize: fontSize.caption,
  },
  pDark: {
    color: textColor.primaryDarkColor,
    fontSize: fontSize.caption,
  },
});
