import * as React from "react";
import { css } from "aphrodite";
import { DebugStyle } from "./DebugStyle";
import { A } from "../../atoms/A/A";
import { Paragraph } from "../../atoms/Paragraph";

export interface IProps {
  currentUser: any;
}

export const Debug: any =
  process.env.NODE_ENV !== "production"
    ? (props: IProps): React.ReactElement | null => {
        const { currentUser } = props;
        const href: string = currentUser
          ? "/users/sign_out"
          : "/debugs/user_sign_in?user_id=1";
        return (
          <div className={css(DebugStyle.block)}>
            <A href={href} title="login">
              <Paragraph styles={DebugStyle.p}>
                {currentUser ? "> ログアウト" : "> ログイン"}
              </Paragraph>
            </A>
          </div>
        );
      }
    : (props: IProps): React.ReactElement | null => {
        const { currentUser } = props;
        if (!currentUser) {
          return null;
        }
        const href: string = "/users/sign_out";
        return (
          <div className={css(DebugStyle.block)}>
            <A href={href} title="login">
              <Paragraph styles={DebugStyle.p}>{"> ログアウト"}</Paragraph>
            </A>
          </div>
        );
      };
// : (_: any): null => {
//     return null;
//   };
