import * as React from "react";
import { css, StyleSheet } from "aphrodite";
import { gridStyle } from "main/javascripts/styles/gridStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { Debug } from "../../molecules/Debug";
import { Paragraph } from "../../atoms/Paragraph";
import { A } from "../../atoms/A";
import { ICurrentUser } from "main/javascripts/models/CurrentUser";
import {
  backgroundColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import {
  maxBreakpoint,
  moreThanBreakpoint,
} from "main/javascripts/styles/base/responsiveStyle";
import { fontSize, letterSpacing } from "../../../styles/base/typographyStyle";

interface IProps {
  currentUser: ICurrentUser | null;
}

export class FooterTravelvision extends React.Component<IProps> {
  public render(): JSX.Element {
    return (
      <footer className={css(FooterStyle.footer)}>
        <div className={css(FooterStyle.mainBlock, gridStyle.grid)}>
          <div className={css(FooterStyle.block, gridStyle.col6)}>
            <A href="/pages/index.html" title="会社概要">
              <Paragraph styles={FooterStyle.link}>運営会社</Paragraph>
            </A>
            <A href="/" title="Travelvision">
              <Paragraph styles={FooterStyle.link}>Travelvision</Paragraph>
            </A>
          </div>
          <div className={css(FooterStyle.block, gridStyle.col6)}>
            <A href="/pages/privacypolicy.html" title="プライバシーポリシー">
              <Paragraph styles={FooterStyle.link}>
                プライバシーポリシー
              </Paragraph>
            </A>
            <A href="/pages/kiyaku" title="利用規約">
              <Paragraph styles={FooterStyle.link}>利用規約</Paragraph>
            </A>
          </div>
        </div>
        <Debug currentUser={this.props.currentUser} />
      </footer>
    );
  }
}
export interface IStyle {
  footer: any;
  mainBlock: any;
  block: any;
  logo: any;
  link: any;
}

export const FooterStyle: IStyle = StyleSheet.create({
  footer: {
    backgroundColor: backgroundColor.primaryLightColor,
    boxShadow: "0 -3px 10px rgb(0, 0, 0, 0.08)",
    paddingBottom: "3rem",
  },
  mainBlock: {
    maxWidth: maxBreakpoint.small,
    margin: "auto",
    padding: "2.5rem 0",
  },
  block: {
    margin: "auto",
    padding: space.block,
    boxSizing: "border-box",
    textAlign: "center",
    position: "relative",
  },
  logo: {
    height: "1.5rem",
    width: "auto",
    transform: "translateX(-3%)",
    [moreThanBreakpoint("desktop")]: {
      height: "2rem",
    },
  },
  link: {
    color: textColor.primaryDarkColor,
    textDecoration: "none",
    letterSpacing: letterSpacing.text,
    fontSize: fontSize.body,
    opacity: 0.7,
    transition: "opacity 0.3s ease",
    ":hover": {
      opacity: 1,
    },
  },
});
