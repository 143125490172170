import * as React from "react";
import { css } from "aphrodite";
import { ImgStyle } from "./ImgStyle";

interface IProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  styles?: any;
}

export const Img: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { styles, ...rest } = props;
  return <img className={css(ImgStyle.img, styles)} {...rest} />;
};
