import { StyleSheet } from "aphrodite";
import {
  fontSize,
  lineHeight,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import { textColor } from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";

interface IStyle {
  paragraph: any;
}

export const ParagraphStyle: IStyle = StyleSheet.create({
  paragraph: {
    fontSize: fontSize.body,
    lineHeight: lineHeight.body1,
    fontWeight: fontWeight.regular,
    color: textColor.secondaryDarkColor,
    margin: 0,
    padding: space.atom,
    wordWrap: "break-word",
  },
});
